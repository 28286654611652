export default (props) => {

  const canExportData = Object.hasOwnProperty.call(window, 'showSaveFilePicker')

  const checkIfCanExportData = () => {

    if (!canExportData) {
      // eslint-disable-next-line
      window.alert('This action is not supported in your browser. Please use Chrome.')
    }

    return canExportData

  }

  const exportAsCSV = (filename, data = '') => {

    if (!checkIfCanExportData()) return null

    const savePickerOptions = {
      suggestedName: filename,
      types: [{
        description: 'CSV file',
        accept: { 'text/csv': ['.csv'] },
      }],
    }

    return window.showSaveFilePicker(savePickerOptions)
      .then((fileHandle) => {
        return fileHandle.createWritable()
      })
      .then((writableStream) => {
        return writableStream.write(new Blob([data], { type: 'text/plain' }))
          .finally(() => {
            return writableStream.close()
          })
      })
      .then(() => {
        return true
      })
      .catch((error) => {
        if (error.message !== 'The user aborted a request.') throw error
        return false
      })
  }

  return {
    exportAsCSV,
    canExportData,
    checkIfCanExportData,
  }

}
