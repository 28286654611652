<template>
  <div class="pagination-controls">
    <div class="total-count">
      {{ totalCountSentence }}
    </div>
    <div class="pagination-buttons" v-if="paginationList.totalPages > 1">
      <button
        class="ghost navigation-button"
        :disabled="paginationList.currentPageNumber === 1"
        @click="loadPage(1)"
      >
        <LastIcon class="transform rotate-180" />
      </button>
      <button
        class="ghost navigation-button"
        :disabled="paginationList.currentPageNumber === 1"
        @click="loadPage(paginationList.currentPageNumber - 1)"
      >
        <NextIcon class="transform rotate-180" />
      </button>
      <button
        :key="pageNumber"
        class="page-button"
        @click="loadPage(pageNumber)"
        v-for="pageNumber in pageButtons"
        :class="{ 'tertiary': pageNumber !== paginationList.currentPageNumber}"
      >
        {{ formatNumber(pageNumber) }}
      </button>
      <button
        class="ghost navigation-button"
        :disabled="paginationList.currentPageNumber === paginationList.totalPages"
        @click="loadPage(paginationList.currentPageNumber + 1)"
      >
        <NextIcon />
      </button>
      <button
        class="ghost navigation-button"
        :disabled="paginationList.currentPageNumber === paginationList.totalPages"
        @click="loadPage(paginationList.totalPages)"
      >
        <LastIcon />
      </button>
    </div>
    <!--
      this is a dummy element with the same content as the .total-count
      container at the top

      this is here solely to keep the pagination buttons centered by occupying
      the same amount of space on both sides of the pagination buttons
    -->
    <div class="total-count invisible hidden md:block">
      {{ totalCountSentence }}
    </div>
  </div>
</template>

<script>

  import useFilters from '@/composables/useFilters'

  import NextIcon from '@/assets/icons/next.svg'
  import LastIcon from '@/assets/icons/next-double.svg'

  export default {
    emits: ['pageload'],
    components: {
      NextIcon,
      LastIcon,
    },
    props: {
      paginationListName: {
        type: String,
        required: true,
      },
      maxButtons: {
        type: Number,
        default: 5,
      },
    },
    setup() {

      const { formatNumber } = useFilters()

      return { formatNumber }

    },
    computed: {
      paginationList() {
        return this.$store.state.pagination[this.paginationListName]
      },
      lastPossibleStartPage() {
        return Math.max(this.paginationList.totalPages - this.maxButtons + 1, 1)
      },
      pageButtons() {

        const pageButtons = []
        const endPage = Math.min(this.startPage + this.maxButtons - 1, this.paginationList.totalPages)

        for (
          let pageNumber = this.startPage;
          pageNumber <= endPage;
          pageNumber++
        ) {
          pageButtons.push(pageNumber)
        }

        return pageButtons

      },
      totalCountSentence() {
        const totalSkippedResults = this.paginationList.pageSize * (this.paginationList.currentPageNumber - 1)
        return `Viewing ${this.formatNumber(totalSkippedResults + 1)} - ${this.formatNumber(totalSkippedResults + this.paginationList.currentPage.length)} of ${this.formatNumber(this.paginationList.totalItems)}`
      },
    },
    watch: {
      'paginationList.currentPageNumber': function paginationListCurrentPageNumber(newValue, oldValue) {
        this.updateStartPage(newValue)
      },
    },
    data() {
      return {
        startPage: 1,
      }
    },
    created() {
      this.updateStartPage(this.paginationList.currentPageNumber)
    },
    methods: {
      updateStartPage(pageNumber) {

        const firstPageButton = this.pageButtons[0]
        const lastPageButton = this.pageButtons[this.pageButtons.length - 1]

        if (pageNumber === firstPageButton) {
          this.startPage = firstPageButton - this.maxButtons + 1
        } else if (pageNumber === lastPageButton) {
          this.startPage = lastPageButton
        } else if (pageNumber < this.startPage) {
          this.startPage = pageNumber
        } else if (pageNumber > lastPageButton) {
          this.startPage = pageNumber
        }

        this.startPage = Math.max(this.startPage, 1)
        this.startPage = Math.min(this.startPage, this.lastPossibleStartPage)

      },
      loadPage(pageNumber) {

        return this.$store
          .dispatch('pagination/LOAD_PAGE', {
            listName: this.paginationListName,
            pageNumber,
          })
          .then(() => {
            this.$emit('pageload', pageNumber)
          })

      },
    },
  }

</script>

<style lang="stylus" scoped>

  .pagination-controls
    @apply my-12

    @apply flex
    @apply gap-y-6
    @apply items-center
    @apply justify-between
    @apply flex-col-reverse

    +breakpoint(md)
      @apply flex-row

  .pagination-buttons
    @apply max-w-full
    @apply overflow-x-auto

    @apply flex
    @apply gap-2
    @apply flex-wrap
    @apply justify-center

    max-width: 100%

    button
      @apply h-8
      @apply py-0
      @apply px-2
      @apply text-base
      @apply font-normal

      min-width: 2rem
      min-height: unset

      &.navigation-button
        @apply p-0
        @apply w-8
        @apply h-8

        @apply flex
        @apply justify-center

        svg
          @apply w-4
          @apply h-4

  .total-count
    @apply text-base
    @apply text-gray-600

</style>
